var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-details g-content"},[_c('div',{staticClass:"g-content-l"},[_c('div',{staticClass:"l-top"},[_c('div',{staticClass:"l-banner"},[(!_vm.isLiveTime)?[_c('div',{staticStyle:{"height":"100%"},on:{"mouseenter":function($event){_vm.showbutton = true},"mouseleave":function($event){_vm.showbutton = false}}},[_c('swiper',{ref:"swiperTop",staticClass:"my-swiper gallery",class:_vm.swiperStop ? 'swiper-no-swiping' : '',attrs:{"options":_vm.swiperOptionTop}},[(_vm.videos && _vm.videos.url)?_c('swiper-slide',[_c('videoPlayer',{attrs:{"url":_vm.videos.url,"cover":_vm.videos.cover},on:{"VideoPaly":_vm.VideoPaly,"VideoPalyfalse":_vm.VideoPalyfalse}})],1):_vm._e(),_vm._l((_vm.banner),function(item,index){return _c('swiper-slide',{key:'gSwiperCommon-' + index},[_c('img',{staticClass:"swiper-cover",attrs:{"src":item}})])})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperList.length > 1 && _vm.showbutton),expression:"swiperList.length > 1 && showbutton"}]},[_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})])],1),_c('p',{staticClass:"l-banner-bot"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/img/icon-zs.png")}}),_vm._v(" "+_vm._s(_vm.actData.likeCount)+" ")])])]:[(_vm.isViewLive && _vm.isViewLive.code != 1)?_c('div',{staticClass:"live-layer",style:({
              'background-image': _vm.actData.liveBackgroundUrl
                ? 'url(' + _vm.actData.liveBackgroundUrl + ')'
                : 'url(' + _vm.option.cove + ')',
            }),on:{"click":_vm.payTouch}},[_vm._m(0)]):[(_vm.liveStatus === 0)?_c('liveIng'):_vm._e(),(_vm.liveStatus === 2)?_c('liveAway'):_vm._e(),(_vm.liveStatus === 1 && _vm.liveUrl && !_vm.isBlack)?_c('liveVideo',{attrs:{"url":_vm.liveUrl,"cover":_vm.option.cover,"liveStatus":_vm.liveStatus,"showBg":_vm.showBg}}):_c('div',{staticClass:"live-layer",staticStyle:{"width":"100%","height":"100%","background-size":"100%"},style:({
                'background-image': _vm.actData.liveBackgroundUrl
                  ? 'url(' + _vm.actData.liveBackgroundUrl + ')'
                  : 'url(' + _vm.option.cove + ')',
              })})]]],2),_c('div',{staticClass:"l-banner-bot"},[_c('p',{staticClass:"l-title-r-p1",class:_vm.isFollow ? 'l-title-r-yes' : '',on:{"click":function($event){return _vm.follow()}}},[(!_vm.isFollow)?_c('span',{staticClass:"heart"},[_c('i',{staticClass:"icon iconfont icon-love"}),_vm._v(" 关注 ")]):_c('span',{staticClass:"heart"},[_vm._v(" 已关注 ")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.actData.followCount))])]),_c('span',{staticClass:"likeCount",on:{"click":_vm.zsGoto}},[_c('img',{attrs:{"src":require("@/assets/img/icon-zs.png")}}),_vm._v(" "+_vm._s(_vm.actData.likeCount)+" ")]),_c('liveDianzan')],1)]),_c('div',{staticClass:"l-title"},[_c('div',{staticClass:"l-title-l"},[_c('div',{staticClass:"l-title-l-img"},[_c('img',{attrs:{"src":_vm.banner[0]}})]),_c('div',{staticClass:"l-title-l-info"},[_c('div',{staticClass:"h3"},[_c('h3',[_vm._v(_vm._s(_vm.actData.title))]),(_vm.actData.liveStateIndex === 1)?_c('div',{staticClass:"ml5-brand"},[_c('span',{staticClass:"u-brand2"},[_vm._v("直播中")])]):_vm._e()]),_c('p',[_vm._v(" 时间："+_vm._s(_vm._f("moment")(_vm.actData.startTime,"YYYY/MM/DD HH:mm"))+"~"+_vm._s(_vm._f("moment")(_vm.actData.endTime,"YYYY/MM/DD HH:mm"))+" ")]),_c('p',[_vm._v("地点："+_vm._s(_vm.actData.address))])])]),(!this.actData.isExhibitionActivity)?_c('div',{staticClass:"l-title-r"},[(_vm.actData.enabledBuyingTicket)?_c('p',{staticClass:"l-title-r-p2",on:{"click":function($event){return _vm.goQrCode()}}},[_vm._v(" 购票 ")]):[(_vm.openJoin)?_c('p',{staticClass:"l-title-r-p2",on:{"click":function($event){return _vm.LinkToActReg(_vm.actId)}}},[_vm._v(" 报名 ")]):_vm._e()]],2):_vm._e()]),_c('div',{staticClass:"l-nav"},[_c('div',{staticClass:"type-tab"},_vm._l((_vm.typeList),function(item,index){return _c('span',{key:index,class:_vm.activeIndex == index ? 'active' : '',on:{"click":function($event){_vm.activeIndex = index;
            _vm.selectTab(item);}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.introduction && _vm.introduction.length),expression:"introduction && introduction.length"}],staticClass:"m-card3",attrs:{"id":"Act"}},[_vm._m(1),_c('div',{staticClass:"mc-content detail"},[(_vm.introduction && _vm.introduction.length)?_c('div',{staticClass:"comm_text"},_vm._l((_vm.introduction),function(item,index){return _c('div',{key:index},[(item.text)?_c('p',{staticClass:"int-text",style:({
                'font-size': item.text.fontSize,
                color: item.text.color,
                'text-align': item.text.position,
              }),domProps:{"innerHTML":_vm._s(_vm.$service.replaceSpeace(item.text.content))}}):_vm._e(),(item.picture && item.picture.type == 1)?_c('div',{staticClass:"int-img",style:({
                'padding-left': item.picture.padding + 'px',
                'padding-right': item.picture.padding + 'px',
              })},_vm._l((item.picture.list),function(itm,idx){return _c('div',{key:idx,style:({ 'margin-bottom': item.picture.spacing + 'px' })},[_c('img',{staticStyle:{"width":"100%","display":"block"},attrs:{"src":itm.url}})])}),0):_vm._e(),(
                item.picture &&
                item.picture.type == 2 &&
                item.picture.list &&
                item.picture.list.length
              )?_c('div',{staticClass:"int-img",staticStyle:{"width":"750px"}},[_c('Carousel',{attrs:{"loop":"","autoplay":"","radius-dot":true,"autoplay-speed":5000}},_vm._l((item.picture.list),function(item,index){return _c('CarouselItem',{key:'pic' + index},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.url}})])}),1)],1):_vm._e(),(item.video)?_c('div',{staticClass:"int-video",staticStyle:{"position":"relative","margin-top":"20px"}},[_c('videoPlayer',{attrs:{"url":item.video.link,"cover":item.video.cover,"time":item.video.Time}})],1):_vm._e()])}),0):_vm._e()])]),(_vm.actData && _vm.actData.showSchedule)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dateFinish),expression:"dateFinish"}],staticClass:"m-card3",attrs:{"id":"Date"}},[_vm._m(2),_c('div',{staticClass:"mc-content"},[(_vm.actData)?_c('actDate',{attrs:{"actData":_vm.actData},on:{"finishInit":_vm.finishDate}}):_vm._e()],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ImgFinish),expression:"ImgFinish"}],staticClass:"m-card3",attrs:{"id":"Img"}},[_vm._m(3),_c('div',{staticClass:"mc-content"},[_c('imgLive',{on:{"finishImg":_vm.finishImg}})],1)])]),_c('div',{staticClass:"g-content-r"},[_c('userInfo',{ref:"barrage",on:{"watchliveSend":_vm.send}}),(_vm.actData)?_c('compInfo',{attrs:{"actData":_vm.actData}}):_vm._e()],1),_c('qrCode',{ref:"qrCode",attrs:{"url":_vm.codeUrl}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"live-layer-img"},[_c('img',{attrs:{"src":require("@/assets/img/video-icon.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mc-top"},[_c('div',{staticClass:"mc-top-title"},[_c('span',{staticClass:"mc-top-text mc-top-txt s-bold"},[_vm._v("活动详情")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mc-top"},[_c('div',{staticClass:"mc-top-title"},[_c('span',{staticClass:"mc-top-text mc-top-txt s-bold"},[_vm._v("日程安排")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mc-top"},[_c('div',{staticClass:"mc-top-title"},[_c('span',{staticClass:"mc-top-text mc-top-txt s-bold"},[_vm._v("精彩瞬间")])])])}]

export { render, staticRenderFns }