<template>
  <div class="user-info m-card3">
    <div class="type-tab">
      <span
        v-for="(item, index) in type"
        :key="index"
        @click="
          activeIndex = index;
          selectTab(item);
        "
        :class="activeIndex == index ? 'active' : ''"
      >
        {{ item }}
      </span>
    </div>
    <div class="type-user-msg type-main" v-if="activeIndex == 0">
      <div class="barrage" ref="wrapper">
        <div id="barrage_wrap" class="barrage_wrap">
          <div class="bar_block" v-for="(item, index) in barrageList" :key="index + 'bar'">
            <div class="barragediv entry" v-if="item.isEntry">
              欢迎<span>{{ item.name }}</span
              >进入大厅
            </div>
            <div class="barragediv " v-else>
              <span class="barrage_name">{{ item.name }}：</span>
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="barrage-send">
        <Input search v-model="sendInfo" enter-button="发送" placeholder="说点什么~" style="width: 270px" @click.native="searchHandle" />
      </div>
    </div>
    <div class="type-live-user type-main" v-else>
      <!-- <Scroll :on-reach-bottom="handleReachBottom">
                <div v-for="(item, index) in list1" :key="index" style="margin: 32px 0">
                    Content {{ item }}
                </div>
            </Scroll> -->
      <div class="lo-item" v-for="(item, index) in dataList" :key="index">
        <div class="loi-info">
          <img :src="JSON.parse(item.userInfo).avatarUrl" v-if="JSON.parse(item.userInfo).avatarUrl" />
          <img src="@/assets/img/empty.png" v-else />
          <span>{{ JSON.parse(item.userInfo).name || JSON.parse(item.userInfo).nickName || "游客" }}</span>
        </div>
        <div class="loi-box">
          <div class="loi-brand" v-if="item.likeCount"><img src="@/assets/img/icon-zs.png" />{{ item.likeCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      type: ["互动交流", "在线观众"],
      activeIndex: 0,
      sendInfo: "",
      stortBarrageList: [], //超过100条储存数组
      newBarrageList: [],
      barrageList: [], //接受留言列表

      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 20,
      dataList: [],
    };
  },
  computed: {
    ...mapGetters({
      // roomid: "getRoomid",    //房间号
      user: "getUser",
    }),
    roomid() {
      return this.$route.query.id;
    },
    isRegOk() {
      return this.user && this.user.inMember && this.user.inMember.userRole && this.user.inMember.userRole != "VISITOR" && this.user.inMember.userRole != "Visitor";
    },
    isLogin() {
      return this.user&&this.user.userId;
    },
  },
  created() {
    this.getTopBarrage();
  },
  updated() {
    this.$nextTick(() => {
      if (this.activeIndex == 0) {
        this.init();
      }
    });
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      getBarrage: "act/getBarrage",
      unverifiedDialog: "unverifiedDialog",
    }),
    init() {
      this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight - 40;
    },
    //获取前20条留言
    async getTopBarrage() {
      let res = await this.getBarrage(this.roomid);
      if (res) {
        let arr = [];
        res.forEach((el) => {
          if (el.isShow && el.userInfo) {
            arr.push({
              id: el.id,
              isEntry: false,
              name: JSON.parse(el.userInfo).nickName || JSON.parse(el.userInfo).name,
              text: el.content,
            });
          }
        });
        this.barrageList = [...this.barrageList, ...arr.reverse()];
      }
    },
    // 观众留言数据
    dataPush(data) {
      this.barrageList.push(data);
    },
    //观众进入判断
    viewerEntry(data) {
      console.log(data, "观众进入判断数据");
      let arr = [...this.barrageList, ...this.newBarrageList];
      let found = arr.some((el) => el.userId === data.userId && data.isEntry);
      console.log(found, "found");
      if (!found) {
        this.dataPush(data);
      }
    },

    // 观众留言
    searchHandle() {
      if (!this.isLogin) {
        this.unverifiedDialog(this);
        return;
      }
       if(this.user&&this.user.inMember.isBlacklist){
        this.$Message.warning('您已被屏蔽');
        return 
      }
      if (/.*1[3|4|5|7|8][0-9]{9}.*/.test(this.sendInfo)) {
        this.$Messag.error("请勿发送手机号！");
        return;
      }
      if (this.sendInfo == "") return;
      this.$emit("watchliveSend", this.sendInfo);
      this.sendInfo = "";
    },

    // 在线观众
    selectTab(type) {
      if (type == "在线观众") {
        this.getOnline();
        this.getfollow();
      }
    },

    async getOnline() {
      let query = `
          query queryLiveViewer($query: QueryInput!){
              queryLiveViewer(query:$query){
                    hasNextPage
                    hasPreviousPage
                    items{
                      helperMoney
                      likeCount
                      roomId
                      userId
                      userInfo
                    }
                    pageIndex
                    pageSize
                    totalCount
                    totalPages
                }
          }`;
      let where = {
        Equal: {
          N: "roomId",
          v: this.roomid, // 有直播时活动id就是房间id
        },
      };
      let order = [
        {
          N: "likeCount",
          v: 0,
        },
        {
          N: "createat",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: 1000,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let res = await this.graphqlPost(opt);
      if (res.data.queryLiveViewer.items) {
        let currentList = res.data.queryLiveViewer.items;
        if (this.pageIndex == 1) {
          this.dataList = currentList;
        } else {
          this.dataList = [...this.dataList, ...currentList];
        }
        this.loading = false;
        if (!res.data.queryLiveViewer.hasNextPage) {
          this.finished = true;
        }
      }
    },
    async getfollow() {
      let query = `
          query queryActivityView($query: QueryInput!){
            queryActivityView(query:$query){
                    hasNextPage
                    hasPreviousPage
                    items{
                        avatarUrl
                        number
                    }
                    pageIndex
                    pageSize
                    totalCount
                    totalPages
            }
          }`;
      let where = {
        Equal: {
          N: "activityId",
          v: this.roomid,
        },
      };
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: 1000,
            where: JSON.stringify(where),
          },
        },
      };
      let res = await this.graphqlPost(opt);
      console.log(res, "111111111");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.user-info {
  height: 558px;
}
.type-tab {
  width: 100%;
  height: 48px;
  background-color: #ededed;
  span {
    display: inline-block;
    text-align: center;
    line-height: 44px;
    width: 50%;
    cursor: pointer;
  }
  .active {
    background: #fff;
    @include font_color(#1890ff);
    border-top: 4px solid #1890ff;
    @include border_color(#1890ff);
  }
}
.type-main {
  height: 510px;
  position: relative;
}
.type-live-user {
  overflow-y: auto;
}
.lo-item {
  display: flex;
  align-items: center;
  padding: 20px 20px 0;
  .loi-info {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 14px;
    color: #333;
    width: 50%;
    //   min-width: 4rem;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    img {
      width: 24px;
      height: 24px;
      background-color: #fff;
      border-radius: 50%;
      border: solid 2px #ccc;
      display: inline-block;
      margin-right: 14px;
    }
  }
  .loi-box {
    display: flex;
    align-items: center;
  }
  .loi-brand {
    display: flex;
    align-items: center;
    border-right: 1px solid #f2f2f2;
    padding: 0 20px;
    &:last-child {
      border-right: none;
    }
    img {
      width: 16px;
      display: inline-block;
      margin-right: 10px;
    }
  }
}
.lo-item:nth-child(1) {
  .loi-info {
    img {
      border: solid 1px #ff3600;
    }
  }
}
.lo-item:nth-child(2) {
  .loi-info {
    img {
      border: solid 1px #ff6e1d;
    }
  }
}
.lo-item:nth-child(3) {
  .loi-info {
    img {
      border: solid 1px #ffc62c;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
// .barrage {
//   z-index: 9;
//   position: absolute;
//   top: 20px;
//   right: 15px;
//   left: 15px;
//   bottom:60px;
//   width: 6rem;
//   transition: all 0.3s;
//   overflow-y: auto;
//   -ms-overflow-style: none;
//   overflow: -moz-scrollbars-none;
//   &::-webkit-scrollbar {
//     width: 0 !important;
//   }
// }
.barrage .scrollstyle {
  width: 50% !important;
  left: 50% !important;
  margin-left: -25% !important;
}
.barrage {
  padding: 20px;
  position: relative;
  overflow-y: auto;
  height: 430px;
}
.barrage .bar_block {
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
  }
}
.barrage-send {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 80px;
  background: #fff;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.barrage .barragediv {
  word-wrap: break-word; /*在长单词或URL地址内部进行换行，防止过长导致自动换行 */
  word-break: break-all; /*允许再单词内换行*/
  display: inline-block;
  color: #333;
  border-radius: 3px;
  line-height: 0.23pox;
  padding: 0 10px;
  text-align: justify;
  vertical-align: top;
  &.hint {
    color: #ffbb7e;
    line-height: 32px;
    padding: 10px;
  }
  &.entry {
    color: #333;
    span {
      color: #333;
      margin: 0 10px;
      color: #ffbb7e;
    }
  }
}
.barrage .barragediv .barrage_name {
  color: #999;
}
</style>
